
* {
  font-family: "Mulish", sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
  color: #000!important;
}
.bounce2 {
  animation: bounce2 2s ease infinite;
  -webkit-animation: bounce2 2s ease infinite;
  -moz-animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-10px);}
}

@-webkit-keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-10px);}
}

@-moz-keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-10px);}
}



.zoomIn {
	animation: zoomEff 10s infinite;
	-webkit-animation: zoomEff 10s infinite;
	-moz-animation: zoomEff 10s infinite;
	transition: 300ms ease-in-out;
  }
  @keyframes zoomEff {
	0% {
	  background-size: 100%;
	}
	50% {
	  background-size: 110%;
	}
	100% {
	  background-size: 100%;
	}
  }
  
@-webkit-keyframes zoomEff {
	0% {
	  background-size: 100%;
	}
	50% {
	  background-size: 110%;
	}
	100% {
	  background-size: 100%;
	}
  }

@-moz-keyframes zoomEff {
	0% {
	  background-size: 100%;
	}
	50% {
	  background-size: 110%;
	}
	100% {
	  background-size: 100%;
	}
  }


  @media only screen and (max-width: 600px) {.zoomIn {
	animation: zoomEff 10s infinite;
	-webkit-animation: zoomEff 10s infinite;
	-moz-animation: zoomEff 10s infinite;
	transition: 300ms ease-in-out;
  }
  @keyframes zoomEff {
	0% {
	  background-size: 380%;
	}
	50% {
	  background-size: 500%;
	}
	100% {
	  background-size: 380%;
	}
  }
  
@-webkit-keyframes zoomEff {
	0% {
	  background-size: 380%;
	}
	50% {
	  background-size: 500%;
	}
	100% {
	  background-size: 380%;
	}
  }

@-moz-keyframes zoomEff {
	0% {
	  background-size: 380%;
	}
	50% {
	  background-size: 500%;
	}
	100% {
	  background-size: 380%;
	}
  }
  }
  